import "./Collection.css";
import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import CollectionPostGridCard from "./Components/CollectionPostGridCard";
import fileDownload from "js-file-download";
import { refunnelLogo } from "../assets";
import axios from "axios";
import { Box } from "@mui/material";
import TopHeader from "../TopHeader";

const Collection = () => {
  let location = useLocation();

  const [error, setError] = useState();
  const [items, setItems] = useState([]);
  const urlParams = new URLSearchParams(location.search);
  const coll_id = urlParams.get("coll_id");
  const coll_name = urlParams.get("coll_name");
  const [brandName, setBrandName] = useState();

  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const currentScreenSizes = {
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  };

  useEffect(() => {
    getCollectionMedia();
  }, []);

  //------------ NEW -------------------

  const downloadMediaAPI = (media_id, content) => {
    content.media.map((obj, index) => {
      if (obj.viewable_media_type === "VIDEO") {
        downloadVideoPostHandler(
          index,
          `${content.creator?.username.replace(/[^a-zA-Z 0-9]+/g, "")}-UGC.mp4`,
          content
        );
      } else {
        downloadImagePostHandler(
          index,
          `${content.creator?.username.replace(/[^a-zA-Z 0-9]+/g, "")}-UGC.png`,
          content
        );
      }
    });
  };

  const downloadImagePostHandler = (media_index, filename, content) => {
    //----- File Downloading Code ----------
    axios
      .get(content.media[media_index].download_url + `?filename=${filename}`, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const downloadVideoPostHandler = (media_index, filename, content) => {
    axios
      .get(content.media[media_index].download_url + `?filename=${filename}`, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  //------------ NEW -------------------

  function getCollectionMedia() {
    try {
      fetch(
        process.env.REACT_APP_API_URI +
          `v1/public/collections/${coll_id}/media`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            //setRegister(!isRegister);
            response.json().then((data) => {
              setBrandName(data.brand_name);
              const newArr = data.media.map((v) => ({
                ...v,
                show_usage_rights: true,
                is_video_playing: false,
                is_marked_as_customer: false,
              }));
              setItems([]);
              setItems(newArr);
            });
          } else {
            setError({
              title: "Error",
              message: "Something went wrong",
            });
          }
        })
        .catch((err) => {
          setError({
            title: "Error",
            message: "Something went wrong",
          });
        });
    } catch (err) {
      setError({
        title: "Error",
        message: "Something went wrong",
      });
    }
  }

  return (
    <div className="center-vertically">
      <TopHeader brandName={brandName} comingFrom="Collection" />
      <Box className="collection_name">{coll_name}</Box>
      {items.length > 0 ? (
        <CollectionPostGridCard
          contentData={items}
          itemWidth={isTabletOrMobile ? 2 : isBigScreen ? 2 : 2}
          downloadMediaAPI={downloadMediaAPI}
          currentScreenSizes={currentScreenSizes}
        />
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default Collection;
